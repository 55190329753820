import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

import '../css/footer.css';

function Footer() {
  let [footerHidden, setFooterHidden] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  useEffect(()=>{
    let handleScroll = () => {
      setFooterHidden(window.pageYOffset <= 0);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [setFooterHidden])

  return (
    <div onClick={scrollToTop} className={`footer ${(footerHidden) ? "is-hidden" : ""}`}>
      <FontAwesomeIcon className="footerIcon" icon={faArrowUp} />
    </div>
  );
}

export default Footer;
