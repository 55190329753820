import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire, faDice, faCode } from '@fortawesome/free-solid-svg-icons'

import favicon from "../images/favicon.ico";
import gameFavicon from "../images/gameFavicon.ico";
import webFavicon from "../images/webFavicon.ico";
import '../css/navbar.css';

function Navbar(props) {
  let location = useLocation();
  let profession = (location.pathname === "/games") ? "games" : (location.pathname === "/web") ? "web" : "wonmin"

  //add profession to main body
  useEffect(() => {
    document.body.className = "";
    document.body.classList.add(profession);
  }, [profession]);

  //dynamically change favicon depending on profession
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = (profession === "wonmin") ? favicon : (profession === "games") ? gameFavicon : webFavicon;
  }, [profession, favicon, gameFavicon, webFavicon]);

  let [navbarBorder, setNavbarBorder] = useState(false);

  //fix the navbar when scroll past
  useEffect(()=>{
    let handleScroll = () => {
      setNavbarBorder(window.pageYOffset > 0);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [setNavbarBorder]);

  return (
    <header id="navbar" className={`is-fixed ${(navbarBorder) ? "border-bottom" : ""}`}>
      <div className="header center">
        <NavLink to="/" className="nav left logoNav noselect">
          <h3 className="link noline">
              1min
          </h3>
          <button type="button" className={`${profession} btn color-always btn--icon`}>
            {profession === "wonmin" && <FontAwesomeIcon icon={faFire} />}
            {profession === "games" && <FontAwesomeIcon icon={faDice} />}
            {profession === "web" && <FontAwesomeIcon icon={faCode} />}
          </button>
        </NavLink>
        <nav className="nav center">
        <ul className="nav__list center">
          { profession === "wonmin" &&
            <li className="nav__list-item">
              <NavLink to="/games" className="link link--nav">games</NavLink>
            </li>
          }
          { profession === "wonmin" &&
            <li className="nav__list-item">
              <NavLink to="/web" className="link link--nav">Web dev</NavLink>
            </li>
          }
          { profession !== "wonmin" &&
            <>
              <li className="nav__list-item">
                <a href={`/${profession}/resume`} target="_blank" rel="noreferrer noopener" className="link link--nav">Resume</a>
              </li>
              <li className="nav__list-item">
                <a target="_blank" rel="noreferrer noopener" href="mailto:1min@unicornwithwings.com" className="link link--nav">Email me</a>
              </li>
            </>
          }
        </ul>
      </nav>
      </div>
    </header>
  );
}

export default Navbar;
