import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import "../css/interests.css";
import loukun from "../images/interests/loukun.jpg";
import houseplants from "../images/interests/houseplants.jpg";
import biking from "../images/interests/biking.jpg";
import jrpg from "../images/interests/jrpg.jpg";
import camping from "../images/interests/camping.jpg";
import hiking from "../images/interests/hiking.jpg";
import mcu from "../images/interests/mcu.jpg";
import swimming from "../images/interests/swimming.jpg";
import pingpong from "../images/interests/pingpong.jpg";
import anime from "../images/interests/anime.jpg";

function Interests(props) {
  let profession = props.profession;
  let color = profession === "games" ? "tertiary" : "quaternary";

  let interests = [
    { link: mcu, name: "MCU" },
    { link: false, name: "Keyboard Shortcuts" },
    { link: loukun, name: "Cats" },
    { link: "https://www.instagram.com/p/CCURKBqHYWS/", name: "日本語" },
    { link: houseplants, name: "Houseplants" },
    {
      link: "https://www.youtube.com/channel/UCLeP7xYgXBD_AYci8SGKPgA",
      name: "Making videos",
    },
    { link: biking, name: "Biking" },
    {
      link:
        "https://www.reddit.com/r/food/search/?q=author%3Asysifuscorp%20%5Bhomemade%5D",
      name: "Cooking",
    },
    { link: "https://steamcommunity.com/id/xcellion", name: "Video games" },
    { link: "https://unicornwithwings.com/", name: "Board games" },
    { link: hiking, name: "Hiking" },
    { link: anime, name: "Anime" },
    { link: camping, name: "Camping" },
    { link: pingpong, name: "Ping Pong" },
    { link: false, name: "Reading game patch notes" },
    { link: swimming, name: "Swimming" },
    { link: jrpg, name: "JRPGs" },
    {
      link: "https://pegasusgames.medium.com/list/terrace-house-01555454757d",
      name: "Terrace House",
    },
  ];

  let allInterests = interests.map((c, i, a) => {
    if (c.link) {
      return (
        <a
          key={`interests_${i}`}
          href={c.link}
          target="_blank"
          rel="noreferrer noopener"
          className="link noline"
        >
          <li
            key={`interests_${i}`}
            className={`skills__list-item btn btn--plain is-bg-${color} is-inverted is-rounded-corner`}
          >
            {c.name}
            <FontAwesomeIcon
              className="inlineFA"
              icon={faArrowUpRightFromSquare}
            />
          </li>
        </a>
      );
    } else {
      return (
        <li
          key={`interests_${i}`}
          className="skills__list-item btn btn--plain is-rounded-corner"
        >
          {c.name}
        </li>
      );
    }
  });

  return (
    <div className="mainBody row">
      <section className="section interests">
        <h2 className="section__title">
          Stuff I like
          <br />
          <span className="project__subtitle">that maybe we can bond over</span>
        </h2>
        <ul className="skills__list">{allInterests}</ul>
      </section>
    </div>
  );
}

export default Interests;
