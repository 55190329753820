import React from 'react'

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import MetaTags from './components/MetaTags.js';
import Navbar from './components/Navbar.js';
import Intro from './components/Intro.js';
import MainPage from './components/MainPage.js';

const Router = (props) => {
  return (
    <BrowserRouter>
      <MetaTags />
      <Navbar />
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/games" element={<MainPage />} />
        <Route path="/web" element={<MainPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router;
