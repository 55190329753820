import React from 'react';

import { Helmet } from "react-helmet";

const MetaTags = (props) => {
  let splashImage = "../images/avatar.jpg";
  let description = "Welcome to the personal website for Wonmin!"
  let title = `Wonmin "1min" Lee's personal website`;

  return (
    <Helmet>
      <meta name="description" data-react-helmet="true" content={ description } />

      <meta itemprop="name" data-react-helmet="true" content={ description } />
      <meta itemprop="description" data-react-helmet="true" content={ description } />
      <meta itemprop="image" data-react-helmet="true" content={ splashImage } />

      <meta property="og:title" data-react-helmet="true" content={ title } />
      <meta property="og:description" data-react-helmet="true" content={ description } />
      <meta property="og:image" data-react-helmet="true" content={ splashImage } />
      <meta property="og:url" data-react-helmet="true" content={ window.location.href } />

      <meta name="twitter:title" data-react-helmet="true" content={ title }></meta>
      <meta name="twitter:description" data-react-helmet="true" content={ description } />
      <meta name="twitter:image" data-react-helmet="true" content={ splashImage } />
    </Helmet>
  )
}

export default MetaTags;
