import React from 'react'
import { useLocation } from 'react-router-dom';

import Intro from "./Intro.js";
import Projects from "./Projects.js";
import Skills from "./Skills.js";
import Education from "./Education.js";
import Press from "./Press.js";
import Experience from "./Experience.js";
import Interests from "./Interests.js";
import Footer from './Footer.js';

import '../css/main.css';

function MainPage(props) {
  let location = useLocation();
  let profession = (location.pathname === "/games") ? "games" : (location.pathname === "/web") ? "web" : "wonmin";

  return (
    <>
      <Intro profession={profession} />
      <Projects profession={profession} />
      <div className="skillsAndEducation">
        <Skills profession={profession} />
        <Education profession={profession} />
      </div>
      <Press profession={profession} />
      <Experience profession={profession} />
      <Interests profession={profession} />
      <Footer />
    </>
  );
}

export default MainPage;
