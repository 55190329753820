import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import "../css/experience.css";

function Experience(props) {
  let profession = props.profession;
  let professionText =
    profession === "games" ? "games" : "software engineering";

  let experienceWorks = [
    {
      name: "Vendelux",
      url: "https://vendelux.com",
      subtitle: "Software Engineer",
      date: "February 2023 - Present",
      type: "games",
      description: (
        <>
          <p>
            Front-end lead for an AI-powered event intelligence Series A
            startup, driving innovation and impact.
          </p>
        </>
      ),
    },
    {
      name: "DomaHub",
      url: "https://domahub.onrender.com/demo",
      subtitle: "Co-Founder & Full Stack Engineer",
      date: "July 2016 - June 2019",
      type: "games",
      description: (
        <>
          <p>
            Domahub was a domain name marketplace where users can create sales
            pages for their domain names and rent them out for hours at a time.
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://medium.com/@domahub/the-end-of-a-journey-acquisition-announcement-dde604055ec3"
            >
              Acquired by Efty in 2019
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </>
      ),
    },
    {
      name: "Pegasus Games",
      url: "https://unicornwithwings.com",
      subtitle: "Founder & Game Designer",
      date: "May 2018 - Present",
      type: "web",
      description:
        "Creating fun games with unique game mechanics or themes. Have created two tabletop games so far, see the links above for more details.",
    },
    {
      name: "Citrix Systems",
      subtitle: "Staff Consultant",
      date: "April 2014 - July 2016",
      type: "both",
      description:
        "Assessed, implemented, and designed various Citrix environments for Fortune 500 companies as part of the company's in-house consulting team.",
    },
  ];

  // function to build out the projects
  let experienceBuilder = (curr, index, array) => {
    if (curr.type === profession || curr.type === "both") {
      return (
        <div
          key={`work_${index}`}
          className="project experience is-rounded-corner"
        >
          <div className="projectDetails">
            <h3 className="projectTitle">
              <a
                target="_blank"
                rel="noreferrer noopener"
                className="link noline"
                href={curr.url}
              >
                {curr.name}
                {curr.url && (
                  <FontAwesomeIcon
                    className="inlineFAtitle"
                    icon={faArrowUpRightFromSquare}
                  />
                )}
              </a>
            </h3>
            <p className="project__subtitle">{curr.subtitle}</p>
            <p className="project__subtitle">{curr.date}</p>
            <div className="project__description">{curr.description}</div>
          </div>
        </div>
      );
    } else {
      return false;
    }
  };

  let allExperiences = experienceWorks.map(experienceBuilder);

  return (
    <div className="mainBody row">
      <section className="section education">
        <h2 className="section__title">
          Other Experiences
          <br />
          <span className="project__subtitle">
            Not related to {professionText}
          </span>
        </h2>
        <div className="projects__grid">{allExperiences}</div>
      </section>
    </div>
  );
}

export default Experience;
