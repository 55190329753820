import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import "../css/skills.css";

function Skills(props) {
  let profession = props.profession;
  let color = profession === "games" ? "tertiary" : "quaternary";

  let skills = [
    { type: "coding", name: "TypeScript" },
    { type: "coding", name: "JavaScript" },
    { type: "coding", name: "MUI" },
    { type: "coding", name: "React" },
    { type: "coding", name: "CSS" },
    { type: "coding", name: "HTML" },
    { type: "coding", name: "NodeJS" },
    { type: "coding", name: "Express" },
    { type: "coding", name: "MySQL" },
    { type: "coding", name: "Git" },
    { type: "coding", name: "Frontend" },
    { type: "coding", name: "Fullstack" },
    { type: "design", name: "Premiere Pro" },
    { type: "design", name: "Illustrator" },
    { type: "design", name: "InDesign" },
    { type: "design", name: "Photoshop" },
    { type: "design", name: "Figma" },
    { type: "design", name: "Audacity" },
    { type: "design", name: "Audition" },
    { type: "marketing", name: "Kickstarter" },
    { type: "marketing", name: "Google Analytics" },
    { type: "marketing", name: "Mailchimp" },
    { type: "marketing", name: "SendGrid" },
    { type: "marketing", name: "Google Tag Manager" },
    { type: "marketing", name: "BackerKit" },
    { type: "marketing", name: "Later For Reddit" },
  ];

  //filters for different skill types
  let [coding, setCoding] = useState(true);
  let [marketing, setMarketing] = useState(false);
  let [design, setDesign] = useState(false);

  let allSkills = skills.map((c, i, a) => {
    let hideIfHidden =
      (c.type === "design" && !design) ||
      (c.type === "coding" && !coding) ||
      (c.type === "marketing" && !marketing)
        ? "is-hidden"
        : "";
    return (
      <li
        key={`skills_${i}`}
        className={`skills__list-item btn btn--plain is-rounded-corner ${hideIfHidden}`}
      >
        {c.name}
      </li>
    );
  });

  //change states depending on what was clicked
  let clickFunction = (type) => {
    switch (type) {
      case "coding":
        setMarketing(false);
        setDesign(false);
        setCoding(true);
        break;
      case "marketing":
        setMarketing(true);
        setDesign(false);
        setCoding(false);
        break;
      case "design":
      default:
        setMarketing(false);
        setDesign(true);
        setCoding(false);
        break;
    }
  };

  return (
    <div className="mainBody row">
      <section className="section skills" id="skills">
        <h2 className="section__title">Skills</h2>
        <div className="checkboxWrapper noselect">
          <label
            onClick={() => {
              clickFunction("coding");
            }}
            className="checkboxContainer"
          >
            <FontAwesomeIcon
              icon={coding ? faCircleDot : faCircle}
              className={coding ? `is-${color}` : ""}
            />
            <span>Coding</span>
          </label>
          <label
            onClick={() => {
              clickFunction("marketing");
            }}
            className="checkboxContainer"
          >
            <FontAwesomeIcon
              icon={marketing ? faCircleDot : faCircle}
              className={marketing ? `is-${color}` : ""}
            />
            <span>Marketing</span>
          </label>
          <label
            onClick={() => {
              clickFunction("design");
            }}
            className="checkboxContainer"
          >
            <FontAwesomeIcon
              icon={design ? faCircleDot : faCircle}
              className={design ? `is-${color}` : ""}
            />
            <span>Design</span>
          </label>
        </div>
        <ul className="skills__list">{allSkills}</ul>
      </section>
    </div>
  );
}

export default Skills;
