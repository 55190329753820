import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import '../css/education.css';

function Education(props) {

  let profession = props.profession;
  let color = (profession === "games") ? "tertiary" : "quaternary";

  let educationPlaces = [
    {
      institute: "Brown University",
      description: "Bachelor of Arts — Economics",
      date: "2010 — 2013",
    },
    {
      institute: "Emory University — Oxford College",
      description: false,
      date: "2009 — 2010",
    },
    {
      institute: "Stuyvesant High School",
      description: false,
      date: "2005 — 2009",
    },
  ];
  let allEducation = educationPlaces.map((c,i,a)=>{
    return (
      <div key={`education${i}`} className="educationWrapper postWrapper">
        <div className="timelineWrapper">
          <FontAwesomeIcon className={`timelineDot is-${color}`} icon={faCircle} />
          <div className="timeline"></div>
        </div>
        <div className="educationDetails">
          <h5>{c.institute}</h5>
          {c.description && <p>{c.description}</p>}
          <p>{c.date}</p>
        </div>
      </div>
    )
  });

  return (
    <div className="mainBody row">
      <section className="section education">
        <h2 className="section__title">Education</h2>
        <div className="educationWrapper">
          { allEducation }
        </div>
      </section>
    </div>
  );
}

export default Education;
