import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNewspaper,
  faArrowUpRightFromSquare,
  faSquareRss,
} from "@fortawesome/free-solid-svg-icons";
import { faReddit } from "@fortawesome/free-brands-svg-icons";

import "../css/press.css";

function Press(props) {
  let profession = props.profession;
  let color = profession === "games" ? "tertiary" : "quaternary";

  let pressArticles = [
    {
      institute: (
        <>
          New York Post<span className="is-hidden-mobile"> news article</span>
        </>
      ),
      description:
        "Burned-out NYC consultant turns office politics into board game.",
      date: new Date("2022-04-30"),
      link:
        "https://nypost.com/2022/04/30/burned-out-nyc-consultant-turns-office-politics-into-board-game/",
      icon: faNewspaper,
    },
    {
      institute: (
        <>
          <span className="is-hidden-mobile">Reddit </span>/r/AMA — 2.5k upvotes
        </>
      ),
      description:
        "During the pandemic, I survived brain surgery, fell in love, made two board games...",
      date: new Date("2022-03-10"),
      link:
        "https://www.reddit.com/r/IAmA/comments/tb2tzd/during_the_pandemic_i_survived_brain_surgery_fell/",
      icon: faReddit,
    },
    {
      institute: (
        <>
          Washington Post<span className="is-hidden-mobile"> news article</span>
        </>
      ),
      description:
        "They left corporate America to be their own boss. This is how it's going.",
      date: new Date("2022-02-18"),
      link:
        "https://www.washingtonpost.com/business/2022/02/18/leaving-corporate-america-creative-job-own-boss/",
      icon: faNewspaper,
    },
    {
      institute: (
        <>
          <span className="is-hidden-mobile">Reddit </span>/r/AMA — 11.9k
          upvotes
        </>
      ),
      description:
        "In the last 5 years, I quit my corporate job, sold my startup company...",
      date: new Date("2021-02-26"),
      link:
        "https://www.reddit.com/r/IAmA/comments/lt2mna/in_the_last_5_years_i_quit_my_corporate_job_sold/",
      icon: faReddit,
    },
  ];

  if (profession === "web") {
    let webArticles = [
      {
        institute: "Vendelux blog",
        description: "Vendelux Raises $14M Series A Led by FirstMark Capital ",
        date: new Date("2023-11-21"),
        link:
          "https://vendelux.com/blog/vendelux-raises-14m-series-a-led-by-firstmark-capital/",
        icon: faSquareRss,
      },
      {
        institute: "DomaHub / Efty blog",
        description: "The end of a journey — Acquisition announcement.",
        date: new Date("2021-04-23"),
        link:
          "https://medium.com/@domahub/the-end-of-a-journey-acquisition-announcement-dde604055ec3",
        icon: faSquareRss,
      },
    ];
    pressArticles = [...webArticles, ...pressArticles];
  }

  if (profession === "games") {
    let gameArticles = [
      {
        institute: (
          <>
            <span className="is-hidden-mobile">Reddit </span>/r/GetMotivated —
            20k upvotes
          </>
        ),
        description: "quit my corporate job to pursue my dreams...",
        date: new Date("2022-04-25"),
        link:
          "https://www.reddit.com/r/GetMotivated/comments/ubo098/image_oc_quit_my_corporate_job_to_pursue_my/",
        icon: faReddit,
      },
      {
        institute: "The City of Kings blog",
        description: "Quick digital demos",
        date: new Date("2022-10-25"),
        link: "https://thecityofkings.com/news/quick-digital-demos/",
        icon: faSquareRss,
      },
      {
        institute: "Stonemaier Games blog",
        description: "The Marketing Power of Effort and Earned Rewards",
        date: new Date("2021-09-19"),
        link:
          "https://stonemaiergames.com/the-marketing-power-of-effort-and-earned-rewards/",
        icon: faSquareRss,
      },
      {
        institute: "Stonemaier Games blog (guest post)",
        description: "Tinder, Reddit…and Kickstarter?",
        date: new Date("2021-02-08"),
        link:
          "https://stonemaiergames.com/tinder-reddit-and-kickstarter-guest-post/",
        icon: faSquareRss,
      },
      {
        institute: (
          <>
            <span className="is-hidden-mobile">Reddit </span>/r/boardgames —
            10.4k upvotes
          </>
        ),
        description:
          "My brother quit his job and made a board game about backstabbing your coworkers...",
        date: new Date("2021-11-25"),
        link:
          "https://www.reddit.com/r/boardgames/comments/r22h8i/my_brother_quit_his_corporate_job_and_made_a/",
        icon: faReddit,
      },
    ];
    pressArticles = [...gameArticles, ...pressArticles];
  }

  let allPress = pressArticles
    .sort((a, b) => (a.date < b.date ? 1 : -1))
    .map((c, i, a) => {
      return (
        <div key={`education${i}`} className="pressWrapper postWrapper">
          <div className="timelineWrapper">
            <FontAwesomeIcon
              className={`timelineDot is-${color}`}
              icon={c.icon}
            />
            <div className="timeline"></div>
          </div>
          <div className="articleDetailWrapper">
            <h5>{c.institute}</h5>
            <p className="press__date">
              {c.date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
              })}
            </p>
            <a
              href={c.link}
              target="_blank"
              rel="noreferrer noopener"
              className="link noline"
            >
              {c.description}
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </div>
      );
    });

  return (
    <div className="mainBody row">
      <section className="section education">
        <h2 className="section__title">Notable Press</h2>
        <div className="pressRows">{allPress}</div>
      </section>
    </div>
  );
}

export default Press;
